import React, { useCallback, useEffect, useMemo, useState } from "react";

// Components
import { Link } from "react-router-dom";
import { IonHeader, useIonRouter } from "@ionic/react";
import { Button, Image, UserPicture } from "@france/superelements/lib";
import ProfileModal from "../ProfileModal/ProfileModal";

// Navigation
import ROUTES from "../../router/constants/routes";

// Style & Configs
import "./MainHeader.scss";
import { createPortal } from "react-dom";
import {
  MSMMediaQueries,
  useLocalize,
  useLocalizeConfig,
  useMediaQuery,
} from "@france/superelements/utils";
import { UserFragment, useGetMeQuery } from "../../gql/hooks";
import useMobileSize from "../../utils/commercialConditions/useMobileSize";

interface MainHeaderProps {
  displayName?: boolean;
  hasProfileModal?: boolean;
  logoMediaQuery?: MSMMediaQueries | "all";
}

const MainHeader: React.FC<MainHeaderProps> = ({
  displayName,
  hasProfileModal = false,
}) => {
  // Hook
  const t = useLocalize();
  const isMobileSize = useMobileSize();
  const mediaQuery = useMediaQuery();
  const { routeInfo, push } = useIonRouter();
  const { data: user } = useGetMeQuery();
  const { currentLang, setLanguage, availableLangs } = useLocalizeConfig();

  // State
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  // Memos
  const langValues = useMemo(
    () =>
      availableLangs.map((value: string) => ({
        text: t(`lang_${value}`),
        value,
      })),
    [availableLangs, t],
  );

  const { firstName, lastName, profilePicture } = useMemo(
    () => user?.me ?? ({} as UserFragment),
    [user?.me],
  );

  // Handlers
  const handleToggleProfile = useCallback(() => {
    if (isMobileSize) {
      push(ROUTES.PROFILE);
    } else {
      setIsProfileOpen(!isProfileOpen);
    }
  }, [isProfileOpen, push, isMobileSize]);

  const handleLangChange = useCallback(
    (value: string = "es") => setLanguage(value),
    [setLanguage],
  );

  useEffect(() => {
    setIsProfileOpen(false);
  }, [routeInfo]);

  return (
    <>
      <IonHeader id="MainHeader">
        <div className="headerToolbar">
          <Link to={ROUTES.HOME}>
            <Image src="header" ext="svg" className="animatedLogo" />
          </Link>
          <div className="spacer" />
          <div className="langButton px-ms">
            <Button
              size="small"
              text={t(`lang_${currentLang}`)}
              dropdownValues={langValues}
              onClick={handleLangChange}
            />
          </div>
          {displayName && !!firstName && !!lastName && (
            <p>
              {firstName} {lastName}
            </p>
          )}
          <div className="deskTopUserPicture">
            <UserPicture
              firstName={firstName}
              lastName={lastName}
              userPicture={profilePicture}
              onClick={handleToggleProfile}
            />
          </div>
        </div>
      </IonHeader>

      {hasProfileModal &&
        mediaQuery === "md" &&
        createPortal(
          <ProfileModal
            isOpen={isProfileOpen}
            onCloseModal={handleToggleProfile}
            className="mainHeaderProfileModal"
          />,
          document.body,
        )}
    </>
  );
};

export default MainHeader;

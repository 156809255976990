import { lazy } from "react";

// Lazy loading
const pages = {
  Home: lazy(() => import("../../pages/Home/Home")),
  PosList: lazy(() => import("../../pages/PosList/PosListPage")),
  PosDetail: lazy(() => import("../../pages/PosDetail/PosDetail")),
  Profile: lazy(() => import("../../pages/Profile/Profile")),
  Materials: lazy(() => import("../../pages/Materials/Materials")),
  PosCreateProposal: lazy(
    () => import("../../pages/PosCreateProposal/PosCreateProposal"),
  ),
  Training: lazy(() => import("../../pages/Trainings/Trainings")),
  Icons: lazy(() => import("../../pages/DevIcons/DevIcons")),
  PosDetailExternal: lazy(
    () => import("../../pages/PosDetailExternal/PosDetailExternal"),
  ),
  ProposalExternal: lazy(
    () => import("../../pages/ProposalExternal/ProposalExternal"),
  ),
  Proposals: lazy(() => import("../../pages/Proposals/Proposals")),
};

export default pages;

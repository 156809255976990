import ROUTES from "./routes";
import { IconType } from "@france/superelements/lib";

export type FooterConfigType = {
  icon: IconType;
  path?: string;
  disabled?: boolean;
  label: string;
  mobileOnly?: boolean;
  trackingName?: string;
  trackingParams?: any;
};

export const footerConfig: FooterConfigType[] = [
  { icon: "home", path: ROUTES.HOME, label: "home", mobileOnly: true },
  { icon: "map-pin", path: ROUTES.POS, label: "pos", trackingName: "open_POS" },
  {
    icon: "plus-square",
    path: ROUTES.SELECT_POS,
    label: "create_alt",
    mobileOnly: true,
    trackingName: "proposal_start",
    trackingParams: { type: "Complete" },
  },
  {
    icon: "document",
    path: ROUTES.MATERIALS,
    label: "catalog",
    trackingName: "open_materials",
  },
  { icon: "folder", path: ROUTES.PROPOSALS, label: "archive" },
];

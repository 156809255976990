import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { sortProductFormByContainer } from "../sortProductFormByContainer";
import { constructProductsConditionByContainer } from "../../../../utils/commercialConditions/constructProductCondition";
import { ProductConditionType } from "../types/productConditionTypes";

// Classic proposal product conditions
type AddProductConditionsByContainerParams = {
  bottleIds: number[];
  kegIds: number[];
  isPending?: boolean;
  commercialValues: ProductConditionType[];
  params?: { kegs: string[]; bottles: string[] };
};

function AddProductConditionsByContainerReducer(
  state: ProposalState,
  {
    payload: { bottleIds, kegIds, isPending, commercialValues, params },
  }: PayloadAction<AddProductConditionsByContainerParams>,
) {
  const formattedProductConditions = constructProductsConditionByContainer(
    bottleIds,
    kegIds,
    commercialValues,
    isPending,
    params,
  );

  formattedProductConditions.forEach((form) => {
    state.productByFormId[form.formId] = form;

    // Sort form list by keg and bottle
    const productByFormId = state.productByFormId;
    const sortedProductByFormId = sortProductFormByContainer(productByFormId);
    state.productByFormId = sortedProductByFormId;

    const newFormIds = Object.keys(state.productByFormId);
    state.productFormIds = newFormIds;
  });
}

export default AddProductConditionsByContainerReducer;
